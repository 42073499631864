import React, { useCallback, useEffect, useMemo, useState } from "react";
import NewModal from "components/new-modal/new-modal";
import "./withdraw.scss";
import styles from "../deposit-fund/deposit.module.sass";
import { WalletSelectionCards } from "../WalletSelectionCards";
import { Dropdown, Input, Loader } from "components";
import Checkbox from "components/Checkbox";
import { useCurrency, useNetwork, useNotification } from "hooks";
import TextArea from "components/TextArea";
import { Json } from "types";
import { APIS } from "constant";
import { NoBankAccountAdded } from "../NoBankAccountAdded";
import { DepositFunds } from "../deposit-fund";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  AccountState,
  IsEnoghBalance,
  WireTransactionDetailsState,
  WithdrawAmountState,
  depositStepState,
} from "states";
import { countryOption, statesOption } from "./constant";
import { FortressAccountInfo } from "views/MyWallet/store/state";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { WithdrawSuccess } from "@views/wallet/component/withdraw/withdrawSucces";
import InsufficientFunds from "../InsufficientFunds/insufficientFunds";
import { Confirmation } from "@storybook";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CommaSeparatedInput } from "@storybook/comma-separated-input/comma-seprated-input";

const walletForm: any = {
  wire: {
    accountNumber: "",
    routingNumber: "",
    firstName: "",
    lastName: "",
    receiverName: "",
    receiverAddress: {
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: statesOption[0].value,
      country: countryOption[0].value,
    },
    receiverBankName: "",
    receiverBankAddress: {
      street1: "",
      postalCode: "",
      city: "",
      state: statesOption[0].value,
      country: countryOption[0].value,
    },
    funds: "",
    comment: "", // Optional
    mode: "wire", // wire | ach
  },
};

interface CurrentFormState {
  accountNumber: string;
  funds: string;
  receiverBankName: string;
}

export const Withdraw = (props: any) => {
  const { onClose, openModal } = props;
  const [formState, setFormState] = useState(0);
  const [withdrawType, setWithdrawType] = useState("");
  const [showError, setShowError] = useState(false);
  const [currentFormState, setCurrenTformState] = useState<Json | null>(null);
  const { formatCurrencyWithBillion } = useCurrency();
  useEffect(() => {
    setCurrenTformState(walletForm[withdrawType]);
  }, [withdrawType]);
  const resetDepositeState = useResetRecoilState(depositStepState);
  const [depositState, setDepositState] = useRecoilState(depositStepState);
  const [isBalance,setIsBalance]=useRecoilState(IsEnoghBalance)
  const [withdrawConfirmationVisible, setWithdrawConfirmationVisible] = useState(false)
  const { trackEvent } = useFullStoryTrackEvent();
  const [wireTransactionDetails, setWiteTransactionDetails] = useRecoilState(
    WireTransactionDetailsState
  );
  const setWithdrawAmount = useSetRecoilState(WithdrawAmountState);

  const availableBalance = useRecoilValue<any>(FortressAccountInfo);

  const { accountNumber, funds, receiverBankName } = currentFormState || {} as CurrentFormState;
  const maskedAccountNumber = accountNumber ? accountNumber.slice(-4) : "";
  
  const RenderConfirmationWithdrawlAmount = () => {
    return (
      <div className="withdraw-confirmation-panel">
        <div className="panel-details">
          <h4 className="details-heading">Withdraw To</h4>
          <div className="wire-withdraw">
          <p className="details-body" data-tooltip-id={receiverBankName}>{`${receiverBankName}`} </p>
          <ReactTooltip className="theme-tooltip" id={receiverBankName} place="bottom"
                                              content={receiverBankName} />
          <span>{`(${maskedAccountNumber})`}</span>
          </div>
          
        </div>
        <div className="panel-details">
          <h4 className="details-heading">Withdrawal Amount</h4>
          <p className="details-body">${Number(funds).toFixed(2)}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (openModal) {
      setWithdrawType("");
      setFormState(0);
      resetDepositeState();
      setWithdrawConfirmationVisible(false)
    }
  }, [openModal]);

  useEffect(() => {
    setWiteTransactionDetails({});
    setIsBalance(false)
  }, []);

  const [visibleFooter, setVisibleFooter] = useState(true);

  useEffect(() => {
    const formKey = WithdrawForm[withdrawType]?.forms.find((item: any) =>
      ["noBankAdded", "wireTransferInfoCard"]?.includes(item?.key)
    );
    if (formKey && formState > 0) {
      setVisibleFooter(false);
    } else {
      setVisibleFooter(true);
    }
  }, [withdrawType, setVisibleFooter, formState]);

  const [accounts, setAccounts] = useRecoilState(AccountState);
  const { get: getAccounts, loading, data: accountsList } = useNetwork();

  useEffect(() => {
    if (accountsList?.data) {
      setAccounts(accountsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);

  useEffect(() => {
    if (accounts.length === 0) getAccounts(APIS.Accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = useCallback(( e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e?.target;
      if (!target) return;
      let { name,value, pattern } = target;
      let reg = new RegExp(pattern);
      let result = reg.test(value);
      if (result) {
        if (name.includes("state")) {
          value = value.toUpperCase();
        }
        if (name.includes(".")) {
          let splitArr = name.split(".");
          let objKey = splitArr[0];
          let valueKey = splitArr[1];
          setCurrenTformState((prev: any) => ({
            ...prev,
            [objKey]: { ...prev[objKey], [valueKey]: value },
          }));
        } else {
          setCurrenTformState((prev: any) => ({ ...prev, [name]: value }));
        }
      } else {
        //
      }
    },
    []
  );
  const onChangeCommaSeparatedHandler = useCallback((e: any) => {
      const target = e?.target;
      if (!target) return;
      let { name, rawValue: value, pattern } = target;
      let reg = new RegExp(pattern);
      let result = reg.test(value);
      if (result) {
        if (name.includes("state")) {
          value = value.toUpperCase();
        }
        if (name.includes(".")) {
          let splitArr = name.split(".");
          let objKey = splitArr[0];
          let valueKey = splitArr[1];
          setCurrenTformState((prev: any) => ({
            ...prev,
            [objKey]: { ...prev[objKey], [valueKey]: value },
          }));
        } else {
          setCurrenTformState((prev: any) => ({ ...prev, [name]: value }));
        }
      } else {
        //
      }
    },
    []
  );

  const WithdrawForm: any = {
    bank: {
      forms:
        accounts?.length < 1
          ? [
              {
                key: "noBankAdded",
                heading: "",
                subHeading: "",
                component: <NoBankAccountAdded />,
              },
            ]
          : [
              {
                key: "noBankAdded",
                heading: "",
                subHeading: "",
                component: (
                  <DepositFunds
                    handleCloseDeposit={onClose}
                    buttonText="Withdraw"
                    header="Withdraw amount"
                    subHeader="Enter amount you wish to withdraw."
                    transactionType="withdrawl"
                    amountLabel="Amount to Withdraw"
                    fundNote={<></>}
                    bankLabel="Withdrawing to"
                    availableBalance={formatCurrencyWithBillion(
                      availableBalance?.balance ?? 0,
                      2
                    )}
                    component={
                      <div className="availableBalance">
                        <div className="availableBalance__heading">
                          Available Balance:
                        </div>
                        <div className="availableBalance__balance">
                          {formatCurrencyWithBillion(
                            availableBalance?.balance ?? 0,
                            2
                          )}
                        </div>
                        <div className="availableBalance__primary">Max</div>
                      </div>
                    }
                    // component={
                    //   <div className="info-div">
                    //     <p className="heading">Available Balance:</p>
                    // <p className="details">
                    //   {formatCurrencyWithBillion(
                    //     availableBalance?.balance ?? 0,
                    //     2
                    //   )}
                    // </p>
                    //     <span className="primary">Primary</span>
                    //   </div>
                    // }
                  />
                ),
              },
            ],
    },
    wire: {
      forms: [
        {
          heading: "Withdrawal amount",
          subHeading: "",
          className: "wallet-form-100",
          form: [
            {
              title: "Amount to withdraw",
              placeholder: "Amount",
              autoFocus: true,
              htmlType: "reInput",
              inputType: "tel",
              symbol: "Max",
              name: "funds",
              required: true,
              pattern: "^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$",
            },
            // {
            //   title: "note",
            //   placeholder: "write a note here..",
            //   htmlType: "textarea",
            //   inputType: "text",
            //   symbol: "",
            //   name: "comment",
            //   required: false,
            // },
          ],
        },
        {
          heading: "Recipient wire details",
          subHeading: "",
          className: "wallet-form-50",
          form: [
            {
              title: "Account number",
              placeholder: "1234567890",
              htmlType: "input",
              autoFocus: true,
              inputType: "tel",
              symbol: "",
              name: "accountNumber",
              required: true,
              pattern: "^[0-9]{0,17}$",
            },
            {
              title: "Routing number",
              placeholder: "0987654321",
              htmlType: "input",
              inputType: "tel",
              symbol: "",
              name: "routingNumber",
              required: true,
              pattern: "^[0-9]{0,9}$",
            },
            {
              title: "First name",
              placeholder: "Name",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "firstName",
              required: true,
              pattern: "^[a-zA-Z]{0,10}$",
            },
            {
              title: "Last name",
              placeholder: "Last name",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "lastName",
              required: true,
              pattern: "^[a-zA-Z]{0,10}$",
            },
            {
              title: "House/Apartment/Flat",
              placeholder: "House/Apartment/Flat",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverAddress.street1",
              required: true,
              pattern: "^[a-zA-Z0-9s., #-.]{0,40}$",
            },
            {
              title: "ZIP code",
              placeholder: "12345",
              htmlType: "input",
              inputType: "tel",
              symbol: "",
              name: "receiverAddress.postalCode",
              required: true,
              pattern: "^[0-9]{0,6}$",
            },
            {
              title: "Street address",
              placeholder: "Street",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverAddress.street2",
              className: "grid-width-100",
              required: false,
              pattern: "^[a-zA-Z0-9s., #-.]{0,40}$",
            },
            {
              title: "City",
              placeholder: "city",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverAddress.city",
              className: "",
              required: true,
              pattern: "^[a-zA-Z_ ]{0,30}$",
            },
            // {
            //   title: "state",
            //   placeholder: "State",
            //   htmlType: "input",
            //   inputType: "text",
            //   symbol: "",
            //   name: "receiverBankAddress.state",
            //   className: "",
            //   required: true,
            //   pattern: "^[a-zA-Z_ ]{0,14}$",
            // },
            {
              title: "state",
              placeholder: "State",
              htmlType: "dropdown",
              inputType: "text",
              options: statesOption,
              symbol: "",
              name: "receiverAddress.state",
              className: "",
              required: true,
              pattern: "^[a-zA-Z0-9_ ]{0,14}$",
            },
            {
              title: "Country",
              placeholder: "Country",
              htmlType: "dropdown",
              inputType: "text",
              options: countryOption,
              symbol: "",
              name: "receiverAddress.country",
              className: "",
              required: true,
              pattern: "^[a-zA-Z0-9_ ]{0,14}$",
            },
          ],
        },
        {
          heading: "Bank wire details",
          subHeading: "",
          className: "wallet-form-50",
          form: [
            {
              title: "Bank name",
              placeholder: "Name",
              autoFocus: true,
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverBankName",
              className: "grid-width-100",
              required: true,
              pattern: "^[a-zA-Z_ ]{0,30}$",
            },
            {
              title: "Street address",
              placeholder: "Street",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverBankAddress.street1",
              className: "",
              required: true,
              pattern: "^[a-zA-Z0-9s., #-.]{0,40}$",
            },
            {
              title: "ZIP code",
              placeholder: "12345",
              htmlType: "input",
              inputType: "tel",
              symbol: "",
              name: "receiverBankAddress.postalCode",
              required: true,
              pattern: "^[0-9]{0,6}$",
            },
            {
              title: "City",
              placeholder: "city",
              htmlType: "input",
              inputType: "text",
              symbol: "",
              name: "receiverBankAddress.city",
              className: "",
              required: true,
              pattern: "^[a-zA-Z_ ]{0,30}$",
            },
            // {
            //   title: "state",
            //   placeholder: "State",
            //   htmlType: "input",
            //   inputType: "text",
            //   symbol: "",
            //   name: "receiverBankAddress.state",
            //   className: "",
            //   required: true,
            //   pattern: "^[a-zA-Z_ ]{0,14}$",
            // },
            {
              title: "state",
              placeholder: "State",
              htmlType: "dropdown",
              inputType: "text",
              options: statesOption,
              symbol: "",
              name: "receiverBankAddress.state",
              className: "",
              required: true,
              pattern: "^[a-zA-Z_ ]{0,14}$",
            },
            {
              title: "Country ",
              placeholder: "Country ",
              htmlType: "dropdown",
              inputType: "text",
              options: countryOption,
              symbol: "",
              name: "receiverAddress.country",
              className: "",
              required: true,
              pattern: "^[a-zA-Z0-9_ ]{0,14}$",
            },
          ],
        },
      ],
    },
  };

  const { errorNotification, successNotification } = useNotification();

  const formErrorMessage = (element: string, message: string) => {
    const input: any = document.getElementsByName(`${element}`);
    input[0]?.focus();
    errorNotification(message ?? `Please fill the required field`);
  };

  const checkFormValidated = () => {
    const formElements: any = document.querySelectorAll("[required]");

    for (let j = 0; j < formElements.length; j++) {
      let inputName: string = formElements[j]?.name;
      if (
        inputName === "funds" &&
        parseFloat(currentFormState?.funds) >
          parseFloat(availableBalance?.balance)
      ) {
        // formErrorMessage(
        //   inputName,
        //   "Withdraw amount should be less than or equal to your account balance."
        // );
        setIsBalance(true)
        return false;
      } else if (
        inputName === "funds" &&
        parseFloat(currentFormState?.funds) === 0
      ) {
        formErrorMessage(
          inputName,
          "Withdraw amount should be greater than 0."
        );
        return false;
      } else if (inputName?.includes(".")) {
        let splitArr = inputName?.split(".");
        let objKey = splitArr[0];
        let valueKey = splitArr[1];
        if (
          currentFormState?.[objKey]?.[valueKey].trim() === "" ||
          !currentFormState?.[objKey]?.[valueKey].trim()
        ) {
          formErrorMessage(inputName, "This field is required");
          return false;
        } else if (
          (inputName === "receiverAddress.postalCode" ||
            inputName === "receiverBankAddress.postalCode") &&
          currentFormState?.[objKey]?.[valueKey].length < 4
        ) {
          formErrorMessage(inputName, "Please enter a valid postal code");
          return false;
        }
      } else if (
        inputName === "accountNumber" &&
        currentFormState?.[inputName]?.length < 8
      ) {
        formErrorMessage(inputName, "Please enter a valid account number");
        return false;
      } else if (
        inputName === "routingNumber" &&
        currentFormState?.[inputName]?.length !== 9
      ) {
        formErrorMessage(inputName, "Routing number is incorrect");
        return false;
      } else {
        if (
          currentFormState?.[inputName].trim() === "" ||
          !currentFormState?.[inputName].trim()
        ) {
          formErrorMessage(inputName, "This field is required");
          return false;
        }
      }
    }

    return true;
  };
  const { post: sendPayment, loading: paymentLoading } = useNetwork();
  const submitForm = async () => {
    let payload: any = {};
    if (withdrawType === "wire") {
      const { funds, comment, mode, firstName, lastName, ...restData } =
        currentFormState || {};
      payload = {
        destination: {
          wireInfo: {
            ...restData,
            receiverName: firstName.trim() + " " + lastName.trim(),
          },
        },
        funds: parseFloat(funds),
        comment,
        mode,
      };

      if (!payload?.destination?.wireInfo.receiverAddress.street2) {
        delete payload?.destination?.wireInfo.receiverAddress.street2;
      }

      if (!payload?.comment) {
        delete payload?.comment;
      }

      try {
        const response = await sendPayment(APIS.walletPayment, payload, {
          apiResponse: true,
        });

        if (response.message === "ok") {
          setWiteTransactionDetails(response?.data);
          setWithdrawAmount(payload?.funds);
          setWithdrawConfirmationVisible(false);
          setDepositState("success");
                } else {
          errorNotification(response?.message);
        }
      } catch (err: any) {
        errorNotification(err?.message);
      }
    }
  };

  const setForm = () => {
    // validate the form and submit if required
    trackEvent("WITHDRAW_NEXT", {
      method: withdrawType,
    });
    const currentFormLength = WithdrawForm?.[withdrawType]?.forms?.length;
    if (formState !== 0) {
      let isFormValidated = checkFormValidated();
      if (isFormValidated) {
        if (currentFormLength === formState) {
        setWithdrawConfirmationVisible(true);      
        } else {
          setFormState((prev) => prev + 1);
          setShowError(false);
        }
      }
      else{
        setShowError(true);
      }
    } else {
      setFormState((prev) => prev + 1);
      setShowError(false);
    }
  };

  const handleSetDrodown = useCallback((value: string, name: string) => {
    let splitArr = name.split(".");
    let objKey = splitArr[0];
    let valueKey = splitArr[1];

    setCurrenTformState((prev: any) => ({
      ...prev,
      [objKey]: { ...prev[objKey], [valueKey]: value },
    }));
  }, []);

  const getValue = (inputName: any) => {   
    if (inputName?.includes(".")) {
      let splitArr = inputName?.split(".");
      let objKey = splitArr[0];
      let valueKey = splitArr[1];
      return currentFormState?.[objKey]?.[valueKey];
    } else {
      return currentFormState?.[inputName];
    }
  };
  const renderFormInputs = (formData: any) => {
    return formData?.map((items: any) => {
      if (items.htmlType === "div") {
        return items?.component;
      } else if (items.htmlType === "input") {
        return (
          <Input
            key={items.name}
            placeholder={items.placeholder}
            label={items.title}
            className={items?.className}
            value={getValue(items.name)}
            inputType={items.inputType}
            handleChange={onChangeHandler}
            inputName={items.name}
            autoFocus={items.autoFocus}
            required={items.required}
            suffixText={items.symbol}
            readonly={items.readonly}
            pattern={items.pattern ?? ""}
            isError={showError && items.required && !getValue(items.name)}
            errorMessage={`${items.title} is a required field`}
          />
        );
      } else if (items.htmlType === "date") {
        return (
          <>
            <Input
              key={items.name}
              label={items.title}
              placeholder={items.placeholder}
              value={currentFormState?.[items.name]}
              inputType="datetime-local"
              //handleChange={onChangeTimeHandler}
              handleChange={() => {}}
              required={items.required}
              inputName={items.name}
            />
          </>
        );
      } else if (items.htmlType === "textarea") {
        return (
          <TextArea
            key={items.name}
            className={""}
            inputType="text"
            label={items.title}
            placeholder={items.placeholder}
            value={currentFormState?.[items.name]}
            onChange={onChangeHandler}
            name={items.name}
            style={{
              borderRadius: "4px",
            }}
          />
        );
      } else if (items.htmlType === "dropdown") {
        return (
          <Dropdown
            className="asset-dropdown"
            label={items?.title}
            value={getValue(items?.name)}
            setValue={(value: string) => handleSetDrodown(value, items?.name)}
            options={items?.options}
          />
        );
      } else if (items.htmlType === "reInput") {
        return (
          <div className={styles.withdrawAmount}>
            <div className={styles.withdrawAmount__amountContainer}>
              <span className={styles.withdrawAmount__dollarSign}>$</span>
              <CommaSeparatedInput
                type="text"
                name="funds"
                onChange={onChangeCommaSeparatedHandler}
                value={currentFormState?.[items.name]}
                placeholder="0.00"
                className={styles.withdrawAmount__amountInput}
                pattern={items.pattern ?? ""}
                required
                autoComplete="off"
                autoFocus={true}
                style={{
                  width: `${
                    currentFormState?.[items.name]?.toString()?.length+1
                  }ch`,
                  minWidth: "4ch",
                }}
              />
            </div>
            <div className="availableBalance">
              <div className="availableBalance__heading withdrawAvalable">
                Available Balance:
              </div>
              <div className="availableBalance__balance withdrawBalance">
                {formatCurrencyWithBillion(availableBalance?.balance ?? 0, 2)}
              </div>
              <div className="availableBalance__primary">Max</div>
            </div>
            { parseFloat(currentFormState?.funds) >
          parseFloat(availableBalance?.balance) && (
          <div className="wallet_insufficient_fund">
          You cannot withdraw more than your available balance
        </div>
          )}
          </div>
        );
      } else {
        return (
          <Checkbox
            key={items.name}
            note=""
            className={"checkbox"}
            value=""
            //value={auctionFormData[items.name]}
            //onChange={handleCheckBox}
            onChange={""}
            content={items.title}
            name={items.name}
          />
        );
      }
    });
  };

  const getForm = useCallback(() => {
    const currentFormData =
      WithdrawForm?.[withdrawType]?.forms?.[formState - 1];
    // loop through the form and create a component
    const { heading, subHeading, className, form, component } = currentFormData;
    if (heading === "") {
      return <div className="wallet-container-body">{component}</div>;
    } else {
      return (
        <>
          <div className="wallet-modal-container-header">
            <p className="heading">{heading}</p>
            <p className="sub-heading">{subHeading}</p>
          </div>
          <div className="wallet-container-body">
            <div className="form-container">
              <div className={`${className}`}>{renderFormInputs(form)}</div>
             
            </div>
          
          </div>
        </>
      );
    }
  }, [WithdrawForm, formState]);

  const closeModal = useCallback(() => {
    onClose();
    setWiteTransactionDetails({});
    setIsBalance(false)
  }, [formState]);

  const selectedForm: any = useMemo(() => {
    switch (formState) {
      case 0:
        return (
          <>
            <div className="wallet-modal-container-header">
              <p className="heading">Select a withdraw method</p>
              <p className="sub-heading">
                Please select your preferred withdrawal method
              </p>
            </div>
            <div className="wallet-container-body">
              {
                <WalletSelectionCards
                  selected={withdrawType}
                  setWithdrawType={setWithdrawType}
                />
              }
            </div>
          </>
        );
      default:
        return getForm();
    }
  }, [formState, getForm, withdrawType]);

  const currentFormLength = WithdrawForm?.[withdrawType]?.forms?.length;

  const getFormSubmitText: any = {
    ach: "Withdraw",
    wire: "Submit",
  };

  const getButtonText = formState > 0 ? "Back" : "Cancel";

  const GoBackState = () => {
    setFormState((prev) => prev - 1);
    setShowError(false);
  };

  const buttonLabel = paymentLoading ? <Loader className="white-transparent" dimension={15} /> : "Confirm Withdrawal";


  return (
    <NewModal
      isOpen={openModal}
      isStopOutsideClick={false}
      modalName="Withdraw Modal"
      className={`wallet-modal-withdraw ${
        depositState === "success" && "successModal"
      }`}
      closeModal={closeModal}
      title=""
      showCloseBtn={false}
    >
      <div className="wallet-modal-container">
        <i className="ri ri-close-line close-btn" onClick={onClose}></i>
        {!isBalance && !withdrawConfirmationVisible && !wireTransactionDetails?.transactionId && selectedForm }
        {!isBalance && !withdrawConfirmationVisible && !wireTransactionDetails?.transactionId && visibleFooter  && (
          <div className="wallet-modal-footer">
            <button
              disabled={paymentLoading}
              className="btn-secondary"
              onClick={formState > 0 ? GoBackState : closeModal}
            >
              {getButtonText}
            </button>
            <button
              disabled={!withdrawType || paymentLoading || (parseFloat(currentFormState?.funds) >
                parseFloat(availableBalance?.balance) )}
              className="btn-primary"
              onClick={setForm}
            >
              {paymentLoading ? (
                <Loader className="white-transparent" dimension={15} />
              ) : currentFormLength === formState ? (
                getFormSubmitText[withdrawType]
              ) : (
                "Next"
              )}
            </button>
          </div>
        )}
      </div>
      {withdrawConfirmationVisible && (
        <Confirmation
          title="Do you want to proceed with this withdrawal?"
          visible={true}
          description={
            <>
              Please review the details to confirm the transaction. Once confirmed, the amount will be deducted from your available balance.
            </>
          }
          handleModal={()=>submitForm()}
          handleClose={()=>setWithdrawConfirmationVisible(false)}
          confirmationJsxElement={<RenderConfirmationWithdrawlAmount />}
          label={buttonLabel as any}
          cancelLabel="Close"
          isEnableJsxElement={true}
        />
      )}
      {!isBalance && wireTransactionDetails?.transactionId && (
        <WithdrawSuccess
          transactionID={wireTransactionDetails?.transactionId}
          id={wireTransactionDetails?.id}
          status={wireTransactionDetails?.status}
          handleCloseDeposit={onClose}
          transactionHash={wireTransactionDetails?.transactionHash}
        />
      )}
      { isBalance && <InsufficientFunds subHeading={"Please deposit funds to place a transaction."} setVisibleModify={()=>{}} fromAction={false} />}
    </NewModal>
  );
};
