import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { ChatModal } from "./chat-modal";
import { ChatList } from "./chat-list";
import { AssetChatMessages } from "./asset-message";
import {
  AssetChatDataState,
  InitiateChatConfirmationState,
  selectedUserChatState,
  ChatsOnAssetState,
} from "@views/Chat/ChatModal/state";
import { useWebSocket } from "@hooks/web-socket";
import { APIS } from "../../../constant";
import { ReactModal } from "@components/react-modal";
import { Confirmation,Image } from "../../../@storybook";
import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { useRecoilState, useRecoilValue } from "recoil";
import UseChatroomApi from "../store/chatroomApi";
import { userPersonalDetails, userProfileState } from "@states/user";
import { usePrevious } from "@hooks/common";
// @ts-ignore
import { Json } from "@types/common";
import { ActiveCurrencyState } from "@states/exchange";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

export const Chat: React.FC = () => {
  const { socket } = useWebSocket();
  const [isOpen, setIsOpen] = useState(false);
  const [isAssetChatOpen, setIsAssetChatOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const personalData = useRecoilValue(userPersonalDetails);
  const [searchTerm, setSearchTerm] = useState("");

  const profilePic = useRecoilValue(userProfileState);
  const [AssetChatData, setAssetChatData] = useRecoilState(AssetChatDataState);
  const [assetUsers, setAssetUser] = useRecoilState(ChatsOnAssetState);
  const [selectedUserChat, setSelectedUserChat] = useRecoilState(
    selectedUserChatState
  );
  const prevSelectedUserChat = usePrevious(selectedUserChat);
  const [initiateChatConfirmation, setInitiateChatConfirmation] =
    useRecoilState(InitiateChatConfirmationState);

  const { post: createChatId, loading: chatIdLoading } = useNetwork();
  const { errorNotification } = useNotification();
  const { getUserWiseAssetsList } = UseChatroomApi();

  const { firstName, lastName } = personalData;
  const [socketUpdateUser, setSocketUpdateUser] = useState<null | Json>(null);
  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const darkMode = useDarkMode();

  const {svg: {avatar_light, avatar_dark}} = imageConfig;

  useEffect(() => {
    socket?.on("chat", (data) => {
      if (data?.type === "JOINEDROOM") {
        setSelectedUserChat({ ...initiateChatConfirmation, ...data });
        setInitiateChatConfirmation(null);
      }

      if (data?.type === "MESSAGE") {
        setSelectedUserChat((prev) => {
          return {
            ...prev,
            messages: [data, ...(prev?.messages || [])],
          };
        });
      }

      if (data?.type === "ISONLINE" && !!selectedUserChat?.receiverId && selectedUserChat?.receiverId === data?.customerId) {
        setSelectedUserChat((prev) => {
          return {
            ...prev,
            isOnline: data?.isOnline
          };
        });
      }

      if (data?.type === "ISONLINE" && !!selectedAsset) {
        setAssetUser((prev: Json) => {
          let tempData = JSON.parse(JSON.stringify(prev?.data || []));
          const existIdx = tempData?.findIndex((asset: Json) => asset?.receiverId === data?.customerId);
          if (tempData?.[existIdx] && tempData?.[existIdx]?.receiverInfo) tempData[existIdx].receiverInfo.isOnline = data?.isOnline
          return { ...prev, data: [...tempData] }
        });
      }

      if (data?.type === "CHATASSETS") {
        setAssetChatData((prev: Json) => {
          let tempData = JSON.parse(JSON.stringify(prev?.data || []));
          const existIdx = tempData?.findIndex((asset: Json) => asset?.assetId === data?.assetId);
          if (existIdx > -1) tempData?.splice(existIdx, 1);
          if (!!searchTerm && existIdx === -1) return prev
          return { ...prev, data: [data, ...tempData] }
        });
      }

      if (data?.type === "CHATUSERS" && !!selectedAsset) {
        setSocketUpdateUser(data);
      }

      if (data?.type === "ERROR") {
        errorNotification(data?.message);
      }
    });
    return () => {
      socket?.off("chat");
    };
  }, [socket, initiateChatConfirmation, selectedUserChat, searchTerm, selectedAsset]);

  useEffect(() => {
    if (!!prevSelectedUserChat && !selectedUserChat) {
      socket?.emit("chat", {
        type: "EXITROOM",
        chatId: prevSelectedUserChat?.chatId,
        customerId: prevSelectedUserChat?.senderId,
      });
    }
  }, [selectedUserChat]);

  const handleChatConfirmation = useCallback(async () => {
    try {
      const payload = {
        assetId: initiateChatConfirmation?.assetId,
        receiverId: initiateChatConfirmation?.receiverId,
        senderId: initiateChatConfirmation?.senderId,
      };
      const res = await createChatId(APIS.AssetCharts, payload, {
        apiResponse: true,
      });

      if (res?.message === "ok") {
        setSelectedUserChat(null);
        socket?.emit("chat", {
          type: "JOINROOM",
          chatId: res?.data?._id,
          customerId: initiateChatConfirmation?.senderId,
        });
      } else {
        errorNotification(res?.message);
      }
    } catch (e: any) {
      errorNotification(e?.message);
    }
  }, [initiateChatConfirmation]);


  useEffect(() => {
    const options = { updateNewData: false };
    const query = {
      limit: 5,
      offset: 0,
    };
    getUserWiseAssetsList(query, options, setAssetChatData);
  }, []);

  const toggleChat = () => {
    setIsOpen(!isOpen);
    setIsAssetChatOpen(!isAssetChatOpen);
  };

  const findUnRead = useMemo(() => {
    let count = 0;
    for (let i = 0; i < AssetChatData?.data?.length; i++) {
      if (AssetChatData?.data?.[i].unreadCount !== 0) {
        count += AssetChatData?.data?.[i].unreadCount;
      }
    }
    return count;
  }, [AssetChatData]);

  useEffect(()=>{
    setSelectedUserChat(null)
  },[activeCurrency?.id])
  
  return (
    <div className="chat-container">
      {!!initiateChatConfirmation && (
        <ReactModal
          visible={!!initiateChatConfirmation}
          onClose={() => setInitiateChatConfirmation(null)}
        >
          <Confirmation
            title="Chat Initiation Notice"
            visible={!!initiateChatConfirmation}
            description="Your identity will be revealed to the other party and conversations are monitored for security."
            handleModal={handleChatConfirmation}
            handleClose={() => setInitiateChatConfirmation(null)}
            label="Continue"
            cancelLabel="Cancel"
            cancelLoading={chatIdLoading}
            type="buy"
          />
        </ReactModal>
      )}

      <div
        className={classNames("chat-header", { open: isOpen })}
        onClick={toggleChat}
      >
        <div className="avatar">

    <Image
      fileName={darkMode.value ? avatar_dark : avatar_light}
      useFileNameOnFail {...!!profilePic && { url: profilePic }}
      className="item-image"
    />
  
  {findUnRead !== 0 && <span className="notification-badge">{findUnRead}</span>}
</div>

        <div className="name">
          {" "}
          {!!firstName ? firstName + " " + lastName : "LIQUIDITYUSER"}
        </div>
        <div className="arrow">
          {isOpen ? (
            <i className="ri-arrow-drop-down-line ri-xl"></i>
          ) : (
            <i className="ri-arrow-drop-up-line ri-xl"></i>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="chat-content">
          {!!selectedAsset && (
            <AssetChatMessages
              customers={assetUsers}
              setIsAssetChatOpen={setIsAssetChatOpen}
              chatSelectedAsset={selectedAsset}
              setChatSelectedAsset={setSelectedAsset}
              setSocketUpdateUser={setSocketUpdateUser}
              socketUpdateUser={socketUpdateUser}
            />
          )}
          {isAssetChatOpen && (
            <ChatList
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              assetsChat={AssetChatData}
              setAssetChatList={setSelectedAsset}
              setIsAssetChatOpen={setIsAssetChatOpen}
              setChatsOnAsset={setAssetUser}
            />
          )}
        </div>
      )}
      {!!selectedUserChat && <ChatModal />}
    </div>
  );
};
