import React, { useCallback } from "react";
import "./email-verification.scss";
import { Image } from "@storybook";
import { useCookie} from "hooks";
import { ROUTES } from "@routes/constants";
import { imageConfig } from "@utils/imageConfig";


export const EmailVerify = () => {
    const { deleteCookie } = useCookie();
    const { LOGIN } = ROUTES;
    
    const {logo: {Liquid}} = imageConfig;

    const handleLogout = useCallback(() => {
      const isDark: any = localStorage?.getItem("darkMode") ?? "";
      localStorage.clear();
      if (isDark === "true") localStorage?.setItem("darkMode", "true");
      deleteCookie("userDetails");
      window.location.href = LOGIN;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteCookie]);
    
    return (
    <div className="main-container">
          <div className="token-denied-container">
           <div className="logo">
      
          <Image
          className="brand_logo"
          fileName={Liquid}
          />
      </div>
          <div className="token-denied-details ">
            <img
              className="token-denied-image"
              src={"https://storage.googleapis.com/satschel-assets-public/assets/illustrations/emailverfy%20icon.png"}
              alt="access denied"
            />
            <p className="text-url">Link has expired and is no longer valid</p>
            <p className="token-invalid">
            Please contact our support for assistance.
            </p>
            <span className="contact-email">
              Contact<p>:</p>{" "}
              <span className="email-color">support@liquidity.io</span>
            </span>
            <div  className="accessdenied-logout">
            <button  onClick={handleLogout}>
         Close
        </button>
        </div>
        </div>
          </div>
    </div>
      
      ); 
};
