import {
  lazy,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEventHandler,
} from "react";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { SideDrawer } from "components/drawer";
import { Currency } from "views/exchange/components";
import { Settings, User } from "./component";
import Theme from "../Theme";
import {
  InfoState,
  linkedDeviceState,
  linkedDeviceRefresh,
  allCoOwnerState,
  coOwnerApiCall,
  userPersonalDetails,
  isFormVisibleState,
  darkThemeState,
} from "states";
import { ROUTES } from "routes/constants";

import styles from "./Header.module.sass";
import { useCookie, useLocalStorage, useNetwork } from "hooks";
import { APIS } from "constant";
import { Image } from "@storybook";
import { UseTransaction } from "views/MyWallet/store/hooks";
import UAParser from "ua-parser-js";
import { useCalculateWindowSize } from "@hooks/calculate-window-size";
import Notification from "@views/Notification/Notification";
import NotificationBell from "@components/NotificationBell/NotificationBell";
import { NotificationDataState } from "@views/Notification/store/state";
import { trackEventSG } from "libs";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { imageConfig } from "@utils/imageConfig";
import { ChangeUser } from "views/account/components";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import useDarkMode from "use-dark-mode";
import classNames from "classnames";


let navigation = [
  {
    title: "Marketplace",
    url: "/exchange",
  },
  // {
  //   title: "News",
  //   url: "/news",
  // },
  {
    title: "Orders",
    url: ROUTES.ORDERSPAGE,
  },
  {
    title: "Wallet",
    url: ROUTES.WALLET,
  },
  {
    title: "Reports",
    url: "/reports",
  },
  // {
  //   title: "Auction",
  //   url: "/auction",
  // },
];

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setInfoData = useSetRecoilState(InfoState);
  const isRefreshDeviceList = useRecoilValue(linkedDeviceRefresh);
  const { tabPermissions, config } = usePermissionContext();

  const setDevicelist = useSetRecoilState(linkedDeviceState);
  const [isDevicePostApiSuccsess, setIsDevicePostApiSuccsess] = useState(false);
  const coOwnerList = useRecoilValue(allCoOwnerState);
  const iscoOwnerApiCall = useRecoilValue(coOwnerApiCall);
  const userDetails = useRecoilValue<any>(userPersonalDetails);
  const setIsFormVisible = useSetRecoilState(isFormVisibleState);
  const { EXCHANGE } = ROUTES;

  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const props = { visible, setVisible };

  const { post: userDeviceData } = useNetwork();
  const { get: getInfo, data: infoData } = useNetwork();
  const { getFortressAccountInfo } = UseTransaction();
  const { get: getCookieData } = useCookie();
  const [ipAddress, setIPAddress] = useState("");
  const { get: getlinkedDevice } = useNetwork();
  const { get: getLocalStorage } = useLocalStorage();
  const { width } = useCalculateWindowSize(0);
  const darkMode = useDarkMode(false);
  const {
    logo: { Liquid },
    svg: { ReportBugIcon },
  } = imageConfig;

  const isOwner = useMemo(() => {
    const primaryUser: any =
      getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    if (iscoOwnerApiCall) {
      if (coOwnerList?.length > 0 && !primaryUser) {
        return false;
      }
      return true;
    }
  }, [coOwnerList, iscoOwnerApiCall]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  let parser = new UAParser(window.navigator.userAgent);
  let result = parser.getResult();

  useEffect(() => {
    getInfo(APIS.Info);
  }, []);

  useEffect(() => {
    if (infoData?.data?.stellar) {
      setInfoData(infoData?.data?.stellar);
    }
  }, [infoData]);

  useEffect(() => {
    getFortressAccountInfo();
  }, [getFortressAccountInfo]);

  useEffect(() => {
    const userDetails = getCookieData("userDetails");

    if (isOwner && ipAddress) {
      const payload = {
        deviceIp: ipAddress,
        device: result?.device?.model,
        deviceOS: result?.os?.name,
        deviceBrowser: result?.browser?.name,
        deviceType: result?.device?.type ?? "desktop",
        deviceToken: userDetails?.token,
      };
      userDeviceData(APIS.UsersDeviceLogin, payload).then((resp) =>
        setIsDevicePostApiSuccsess(true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCookieData, userDeviceData, ipAddress, isOwner]);

  const getAllLinkedDevice = () => {
    getlinkedDevice(APIS.UsersDeviceLogin).then((resp) => {
      if (resp?.data) {
        setDevicelist(resp?.data);
      }
    });
  };

  useEffect(() => {
    isDevicePostApiSuccsess && getAllLinkedDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevicePostApiSuccsess, isRefreshDeviceList]);

  useEffect(() => {
    if (width >= 1024) {
      setVisible(false);
    }
  }, [width]);

  const body = <Currency />;
  const notification = <Notification />;

  const logo = (
    <div className={styles.logo1}>
      <img src={config?.name !== "Liquidity" ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.dark : Liquid} alt="" className={styles.brand_logo} />
    </div>
  );

  const handleOnClick = useCallback(() => {
    return setVisible(true);
  }, []);

  const handleNavigate = useCallback(
    (url: string) => {
      const { id: userId } = userDetails;
      setVisibleNav(false);
      navigate(url);

      if (url === ROUTES.WALLET) {
        const eventData = {
          timestamp: new Date(),
          user_id: userId,
        };
        trackEventSG("wallet_page_viewed_web", eventData);
      }
    },
    [userDetails]
  );

  const handleLogoClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains(styles.brand_logo)) {
      //window.location.href = EXCHANGE;
      navigate(EXCHANGE);
    }
  };

  const handleReport = () => {
    setIsFormVisible(true);
  };

  // Filter navigation based on config.tabs and permissions
  const filteredTabs: any = useMemo(() => {
    return tabPermissions(navigation)
  }, [tabPermissions]);

  return (
    <>
      <div className={styles.tablet_wrap}>
        <button className={styles.drawer_open_btn} onClick={handleOnClick}>
          <i className="ri-menu-unfold-line" />
        </button>
        <header className={cn(styles.header)}>
          <div className={cn("excahngecontainer", styles.container)}>
            {visible && <SideDrawer {...props} body={body} logo={logo} />}
            <div className={styles.logo}>
              <div className={styles.logo1} onClick={handleLogoClick}>
                <Image className={classNames(styles.brand_logo, { [styles.MlcLogo]: config?.name !== "Liquidity" })} fileName={config?.name !== "Liquidity" ? darkMode.value ? config?.branding?.logo?.dark : config?.branding?.logo?.light : Liquid} />
              </div>
            </div>
            <div
              className={styles.wrapper}
              // style={{ width: "100%" }}
            >
              <div
                style={{ width: "100%" }}
                className={cn(styles.wrap, { [styles.visible]: visibleNav })}
              >
                <nav className={styles.nav}>
                  {filteredTabs?.map(
                    (x: any, index: number) => (
                      // x.dropdown ? (
                      //   <Dropdown
                      //     className={styles.dropdown}
                      //     key={index}
                      //     item={x}
                      //     setValue={setVisibleNav}
                      //   />
                      // ) : (
                      // <NavLink
                      //   className={styles.item}
                      //   // activeClassName={styles.active}
                      //   onClick={() => handleNavigate(x.url)}
                      //   to={x.url}
                      //   key={index}
                      // >
                      <div
                        onClick={() => handleNavigate(x.url)}
                        className={cn(styles.navItem, {
                          [styles.activeTab]: location.pathname.includes(x.url),
                        })}
                        key={index}
                      >
                        {x.title}
                      </div>
                      // </NavLink>
                    )
                    // )
                  )}
                </nav>
                {/* <NavLink
                  className={cn("button-stroke", styles.button)}
                  // activeClassName={styles.active}
                  to="/wallet"
                  onClick={() => setVisibleNav(false)}
                >
                  Wallet
                </NavLink> */}
                {/* <div className={styles.btns}>
              <Link
                className={cn("button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
              >
                Sign Up
              </Link>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
              >
                Login
              </Link>
            </div> */}
              </div>
              <div className={styles.containSettings}>
                {/* <div className={styles.userDropdown}> */}
                <ChangeUser />
                <div className={styles.reportBugIcon} onClick={handleReport}>
                  <img id="reportBug" src={ReportBugIcon} alt="" />
                </div>
                <ReactTooltip
                  anchorId="reportBug"
                  className="theme-tooltip"
                  place="bottom"
                  content="Report a bug"
                />

                <Settings className={styles.settings} />
                {/* <Settings className={styles.settings} />
          <div className={styles.control}>
            <NavLink
              className={styles.activity}
              // activeClassName={styles.active}
              to="/activity"
            >
                              <Notifications className={styles.notifications} />
                            </NavLink>
*/}

                <div className={styles.user_container}>
                  <NotificationBell />
                  <Theme className={styles.theme} icon small={undefined} />
                  {/* <button
                    className={cn("button-stroke button-small", styles.button)}
                    //  activeClassName={styles.active}
                    // to="/wallet"
                    onClick={() => handleNavigate(WALLET)}
                  >
                    Wallet
                  </button> */}
                  <User className={styles.user} />
                </div>
              </div>
            </div>
            {/* <div className={styles.btns}>
            <Link
              className={cn("button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-up"
              onClick={() => setVisibleNav(false)}
            >
              Sign Up
            </Link>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-in"
              onClick={() => setVisibleNav(false)}
            >
              Login
            </Link>
          </div> */}
            {/* <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            ></button> */}
          </div>
          {/* </div> */}
        </header>
      </div>
    </>
  );
};
