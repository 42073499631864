import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { useWebSocket } from "@hooks/web-socket";
// @ts-ignore
import { Json } from "@types/common";
import { userPersonalDetails } from "@states/user";
import { format } from "date-fns";
import UseChatroomApi from "../store/chatroomApi";
import { selectedUserChatState } from "./state";
import { Loader } from "@components/Loader2";
import { formatDateWithCustomFormat } from "@utils/getTime";
import { limitFirstPart, mergeStringWithSymbol } from "@utils/getString";
import InfiniteScroll from "react-infinite-scroll-component";

interface ChatModalProps {}

export const ChatModal: React.FC<ChatModalProps> = () => {
  const initialFilter = { limit: 20, offset: 0 };
  const personalData = useRecoilValue(userPersonalDetails);
  const [isOpen, setIsOpen] = useState(true);
  const { socket } = useWebSocket();
  const [selectedChat, setSelectedChat] = useRecoilState(selectedUserChatState);
  const [message, setMessage] = useState("");
  const chatForm = useRef<any>(null);
  const { getChatHistory, selectedChatHistory } = UseChatroomApi();
  const [filters, setFilters] = useState(initialFilter);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    return () => {
      setSelectedChat(null);
      socket?.emit("chat", {
        type: "EXITROOM",
        chatId: selectedChat?.chatId,
        customerId: selectedChat?.senderId,
      });
    }
  }, []);

  useEffect(() => {
    const options = { updateNewData: false };
    getChatHistory(filters, options, selectedChat?.chatId);
  }, [filters]);

  useEffect(() => {
    if (selectedChatHistory?.data) {
      setSelectedChat((prev: any) => {
        return {
          ...prev,
          messages: [...(prev?.messages || []), ...(selectedChatHistory?.data || [])],
        }
      });
    }
  }, [selectedChatHistory, setSelectedChat]);

  const handleSendMessage = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!message?.trim()) return;
      const payload = {
        type: "MESSAGE",
        chatId: selectedChat?.chatId,
        senderId: selectedChat?.senderId,
        receiverId: selectedChat?.receiverId,
        message: message,
        createdAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX"),
      };
      socket?.emit("chat", payload);

      setSelectedChat((prev) => {
        return {
          ...prev,
          messages: [payload, ...(prev?.messages || [])],
        };
      });
      setMessage("");
    },
    [selectedChat, message]
  );

  const handleKeyDown = (e: any) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      chatForm?.current?.requestSubmit();
    }
  };

  const handleChangeInput = useCallback((e: any) => {
    if (e.target.value?.length <= 160) {
      setMessage(e.target.value)
    }
  }, []);

  return (
    <div className="chat-modal-container">
      <div className={classNames("chat-modal-header", { open: isOpen })}>
        <div className="chat-modal-avatar">
          {selectedChat?.initials}
          <span className={`chat-madal-notification-badge ${!selectedChat?.isOnline ? "offline" : ""}`} />
        </div>
        <div className="chat-modal-details">
          <span className="chat-modal-id">
            {selectedChat?.receiverId || "Chat"}
          </span>
          <span className="chat-modal-subtitle">
            {limitFirstPart(
              mergeStringWithSymbol(selectedChat?.issuerName?.replace(" ", ""), selectedChat?.symbol, "."),
              11,
              "."
            ) ?? "-"}
          </span>
        </div>
        <div className="chat-modal-icons">
          {/*<i className="ri-more-fill chat-modal-icon ri-xl"></i>*/}
          {isOpen ? (
            <i
              className="ri-arrow-drop-down-line chat-close-icon ri-2x"
              onClick={toggleChat}
            ></i>
          ) : (
            <i
              className="ri-arrow-drop-up-line chat-open-icon ri-2x"
              onClick={toggleChat}
            ></i>
          )}
          <button className="chat-modal-close" onClick={() => setSelectedChat(null)}>
            <i className="ri-close-line"></i>
          </button>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="chat-modal-body">
            {!selectedChat?.messages?.length && selectedChatHistory?.loading && <Loader />}
            {!!selectedChat?.messages?.length ? (
              <div className="chat-body" id="chat-modal-scrollableDiv">
                <InfiniteScroll
                  style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  inverse={true}
                  dataLength={selectedChat?.messages?.length || 0}
                  next={() => setFilters((prev: any) => ({ ...prev, offset: filters?.offset + 1 }))}
                  hasMore={
                    selectedChat?.messages?.length >= filters?.offset * filters.limit + filters.limit
                  }
                  loader={""}
                  scrollableTarget="chat-modal-scrollableDiv"
                >
                  {selectedChat?.messages?.map((msgData: Json, idx: number) => (
                    <div key={"chat-mgs-" + idx}>
                      {/*<div className="chat-date">July 5th</div>*/}
                      <div
                        className={`chat-message-user ${
                          msgData?.senderId === personalData?.customerId
                            ? "sender"
                            : "receiver"
                        }`}
                      >
                        <div className="message-content">
                          {msgData?.message}
                          <span className="message-time">
                            {!!msgData.createdAt && formatDateWithCustomFormat(msgData.createdAt, {
                              lessMinute: "Just now",
                              dataFormat: 'hh:mma, MMM dd, yyyy',
                              yesterdayFormat: 'dd/MM/yyyy hh:mm a',
                              hourFormat: 'hh:mm a'
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  {!!selectedChat?.messages?.length && selectedChatHistory?.loading && <Loader dimension={18} />}
                </InfiniteScroll>
              </div>
            ) : (
              !selectedChatHistory?.loading && <>
                <div className="chat-modal-message-icon">
                  <div className="chat-modal-icon-text">
                    <i className="ri-message-2-fill"></i>
                  </div>
                </div>
                <div className="chat-modal-prompt">Start Your Conversation</div>
              </>
            )}
          </div>
          <div className="chat-modal-footer">
            <form
              ref={chatForm}
              className="chat-modal-footer-box"
              onSubmit={handleSendMessage}
            >
              <textarea
                required
                placeholder="Type your message here..."
                className="chat-modal-input"
                value={message}
                onChange={handleChangeInput}
                onKeyDown={handleKeyDown}
              />
              <button className="chat-modal-send-button" type="submit">
                Send
              </button>
            </form>
            <div className="chat-modal-reminder">
              <span>Reminder:</span> All conversations are monitored for security purposes.
                                     Sharing your phone number and email, will result in your account being locked.
            </div>
          </div>
        </>
      )}
    </div>
  );
};
