import { useCallback, useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { APIDesigner } from "views";
import { ROUTES, SIDEBAR_TABS } from "routes";

import { Auction, MyAuctionList } from "views/auction";
import { CreateAuction } from "views/auction/create-auction";
import { Drafts } from "views/auction/drafts/drafts";
import { AdminAuctionDetails } from "views/auction/adminAuctionDetails";
import MintApp from "@views/mint/mint-app";
import { REACT_APP_API_HOST } from "envs";
import { InjectScript } from "./utils";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

export const AdminDashboard = () => {
  const {
    API_DESIGNER,
    AUCTION,
    AUCTION_LIST,
    IPO,
    CREATE_AUCTION,
    EDIT_AUCTION,
    AUCTION_DRAFT,
    AUCTION_DETAILS,
    ASSETS,
    SBA,
    MINTED_APP,
  } = ROUTES;
  
  useEffect(() => {
    const script = new InjectScript({hostUrl: REACT_APP_API_HOST})
    script.inject();
  }, [])
  const { SubRouteEnabled } = usePermissionContext();
  const getAllowedTabs = useCallback(
    (listingData: any) => {
      return listingData.map((item: any) => {
        if (!item.SUB_ROUTE) {
          return item;
        }

        const filteredSubRoutes = item.SUB_ROUTE.filter((subRoute: any) => {
          const titleToCompare =
            subRoute.TITLE === "SBA(7a)"
              ? "sba7"
              : subRoute.TITLE.toLowerCase();

          return SubRouteEnabled("assets")?.includes(titleToCompare);
        });

        return { ...item, SUB_ROUTE: filteredSubRoutes };
      });
    },
    [SubRouteEnabled]
  );
  const lists = SIDEBAR_TABS;
  let listingData = lists.tabs;

  const allowedTabs = getAllowedTabs(listingData) ?? []

  const Availabletab = useMemo(() => {
    const route = allowedTabs[0]?.SUB_ROUTE[0]?.ROUTE;
    const updatedRoute = route?.replace("admin/", "");
    return updatedRoute;
  }, [allowedTabs]);




  const routing = useMemo(() => {
    return (
      <Routes>
        <Route path={API_DESIGNER} element={<APIDesigner />} />
        <Route path={AUCTION} element={<Auction />} />
        <Route path={AUCTION_LIST} element={<MyAuctionList />} />
        <Route path={AUCTION_DRAFT} element={<Drafts />} />
        {/*<Route path={IPO} element={<MintAppIPO />} />*/}
        <Route path={CREATE_AUCTION} element={<CreateAuction />} />
        <Route path={AUCTION_DETAILS} element={<AdminAuctionDetails />} />
        <Route path={EDIT_AUCTION} element={<CreateAuction />} />
        {/*<Route path={ASSETS} element={<MintApp />} />*/}
        {/*<Route path={SBA} element={<MintApp />} />*/}
        <Route path={MINTED_APP} element={<MintApp />} />

        <Route path="*" element={<Navigate to={Availabletab} />} />
      </Routes>
    );
  }, [API_DESIGNER, AUCTION, AUCTION_LIST, AUCTION_DRAFT, CREATE_AUCTION, AUCTION_DETAILS, EDIT_AUCTION, MINTED_APP, Availabletab]);

  return <>{routing}</>;
};

export default AdminDashboard;
