import { FC, useMemo } from "react";
import useDarkMode from "use-dark-mode";
import { useRecoilValue } from "recoil";

import { useCurrency } from "hooks";
import { useFormatNumber } from "utils";
import { Skeleton } from "components";
import { ReportChartsState } from "states";

import styles from "./chart-details.module.sass";
import { BarChart } from "components/Charts";
import "./chart-details.scss";

interface IProfitLoss {
  loader: boolean;
}
export const ProfitLoss: FC<IProfitLoss> = ({ loader }) => {
  const { percentFormat } = useFormatNumber();
  const { formatCurrencyWithBillion } = useCurrency();

  const reportCharts = useRecoilValue(ReportChartsState);

  const profit_Loss = useMemo(() => {
    const { summary, chartData } = reportCharts[0] ?? {};
    const {
      initialInvestment = "0.00",
      totalProfitOrLoss = "0.00",
      totalGainAndLossPercent,
      type = "profit",
    } = summary ?? {};
    const { profitLoss = [], XAxis = [] } = chartData ?? {};
    return {
      initialInvestment,
      totalProfitOrLoss,
      type,
      profitLoss,
      XAxis,
      totalGainAndLossPercent,
    };
  }, [reportCharts]);

  const profitLossPercent = useMemo(() => {
    const value = profit_Loss.totalProfitOrLoss / profit_Loss.initialInvestment;
    return value;
  }, [profit_Loss.initialInvestment, profit_Loss.totalProfitOrLoss]);

  const defaultDataValue = useMemo(() => {
    const checkDataValue = profit_Loss.profitLoss
      .map((item: any) => item.data.reduce((a: number, b: number) => a + b, 0))
      .reduce((a: number, b: number) => a + b, 0);
    let yMaxValue;
    if (checkDataValue === 0) {
      yMaxValue = 100;
    } else {
      yMaxValue = null;
    }
    return yMaxValue;
  }, [profit_Loss.profitLoss]);

  const profitLossDetail = useMemo(
    () => (
      <div className={styles.portfolio}>
        <div className={styles.portfolio__text}>Profit & Loss</div>
        <div className={styles.portfolio__valueData}>
          <div className={styles.portfolio__sub_text}>
          Initial Investment    :{" "}
            <span className={styles.portfolio__sub_text_value}>
              {loader ? (
                <Skeleton />
              ) : (
                formatCurrencyWithBillion(profit_Loss.initialInvestment, 2)
              )}
            </span>
          </div>
          <div className={styles.profitData}>
            <div className={styles.profitData_value}>
              {loader ? (
                <Skeleton />
              ) : (
                formatCurrencyWithBillion(profit_Loss.totalProfitOrLoss, 2)
              )}
            </div>
            {loader ? (
              <Skeleton />
            ) : (
              <div
                className={styles.profitData_percent}
                style={{
                  background:
                    profit_Loss.type === "profit"
                      ? "rgba(186, 220, 186, 1)"
                      : "rgb(255 222 222)",
                }}
              >
                {profit_Loss.type === "profit" ? (
                  <i className={`ri-arrow-up-s-fill ${styles.profit_up}`} />
                ) : (
                  <i className={`ri-arrow-down-s-fill ${styles.loss_down}`} />
                )}
                <span className={styles.totalGainAndLossPercent}>
                  {percentFormat(profit_Loss.totalGainAndLossPercent)}
                </span>
              </div>
            )}
          </div>
        </div>
        <BarChart
          isLoading={loader}
          columnColor={["#33B87A", "#F55353"]}
          data={profit_Loss.profitLoss}
          chartName={"profit"}
          xAxis={profit_Loss.XAxis}
          stacked={true}
          yMax={defaultDataValue}
        />
      </div>
    ),
    [
      defaultDataValue,
      formatCurrencyWithBillion,
      loader,
      percentFormat,
      profit_Loss.XAxis,
      profit_Loss.initialInvestment,
      profit_Loss.profitLoss,
      profit_Loss.totalGainAndLossPercent,
      profit_Loss.totalProfitOrLoss,
      profit_Loss.type,
    ]
  );
  return <>{profitLossDetail}</>;
};
