import React, { useCallback, useEffect, useMemo, useState } from "react";
import NewModal from "components/new-modal/new-modal";
import { WalletSelectionCards } from "../WalletSelectionCards";
import {useNetwork } from "hooks";
import { WireTransferInfoCard } from "./component/WireTransferInfoCard";
import { WireVerifyForm } from "./component/WireVerifyForm";
import { SplashScreen } from "../SplashScreen";
import "./deposit.scss";
import { NoBankAccountAdded } from "../NoBankAccountAdded";
import { useRecoilState } from "recoil";
import { AccountState, depositStepState } from "states";
import { DepositFunds } from "../deposit-fund";
import { APIS } from "constant";

export const Deposit = (props: any) => {
  const { onClose, openModal } = props;
  const [formState, setFormState] = useState(0);
  const [moveNextActive, setMoveNextActive] = useState(true);
  const [visibleFooter, setVisibleFooter] = useState(true);
  const [withdrawType, setWithdrawType] = useState("");
  const [accounts, setAccounts] = useRecoilState(AccountState);

  const [depositState, setDepositState] = useRecoilState(depositStepState);
  const { get: getAccounts, data: accountsList } = useNetwork();
  useEffect(() => {
    if (openModal) {
      setWithdrawType("");
      setFormState(0);
      setMoveNextActive(true);
    }
  }, [openModal]);

  useEffect(() => {
    if (accountsList?.data) {
      setAccounts(accountsList.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsList]);

  useEffect(() => {
    if (accounts.length === 0) getAccounts(APIS.Accounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (withdrawType === "bank" && accounts.length > 0) {
      setFormState(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts?.length]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const WithdrawForm: any = {
    wire: {
      forms: [
        {
          key: "wireTransferInfoCard",
          heading: "Wire transfer detail",
          subHeading:
            "Please wire transfer money to the given account details below.",
          component: <WireTransferInfoCard />,
        },
        {
          heading: "Verify wire details",
          subHeading: "some text",
          component: <WireVerifyForm />,
        },
        {
          heading: "",
          subHeading: "",
          component: <SplashScreen />,
        },
      ],
    },
    bank: {
      forms: [
        {
          key: "noBankAdded",
          heading: "",
          subHeading: "",
          component: <NoBankAccountAdded />,
        },
        {
          heading: "",
          subHeading: "",
          component: <DepositFunds handleCloseDeposit={onClose} />,
        },
      ],
    },
  };

  useEffect(() => {
    const formKey = WithdrawForm[withdrawType]?.forms.find((item: any) =>
      ["noBankAdded", "wireTransferInfoCard"]?.includes(item?.key)
    );
    if (formKey && formState > 0) {
      setVisibleFooter(false);
    } else {
      setVisibleFooter(true);
    }
  }, [WithdrawForm, withdrawType, setVisibleFooter, moveNextActive, formState]);

  const setForm = () => {
    if (!withdrawType) return;
    const currentFormLength = WithdrawForm?.[withdrawType]?.length;

    if (withdrawType === "bank" && accounts.length > 0) {
      setFormState((prev) => prev + 2);
      return;
    }
    if (currentFormLength - 1 === formState) {
      setMoveNextActive(false);
    }
    setFormState((prev) => prev + 1);
  };

  const getForm = useCallback(() => {
    const currentFormData =
      WithdrawForm?.[withdrawType]?.forms?.[formState - 1];
    const { heading, subHeading, component } = currentFormData;

    if (heading === "") {
      return <div className="wallet-container-body">{component}</div>;
    } else {
      return (
        <>
          <div className="wallet-modal-container-header">
            <p className="heading">{heading}</p>
            <p className="sub-heading">{subHeading}</p>
          </div>
          <div className="wallet-container-body">{component}</div>
        </>
      );
    }
  }, [WithdrawForm, formState]);

  const closeModal = useCallback(() => {
    onClose();
    setDepositState("amount");
  }, [formState]);

  const selectedForm: any = useMemo(() => {
    switch (formState) {
      case 0:
        return (
          <>
            <div className="wallet-modal-container-header">
              <p className="heading">Select a deposit method</p>
              <p className="sub-heading">
                Please select your preferred deposit method type.
              </p>
            </div>
            <div className="wallet-container-body">
              {
                <WalletSelectionCards
                  selected={withdrawType}
                  setWithdrawType={setWithdrawType}
                />
              }
            </div>
          </>
        );
      default:
        return getForm();
    }
  }, [formState, getForm, withdrawType]);

  return (
    <NewModal
      isOpen={openModal}
      modalName="Deposit Modal"
      className={`wallet-modal ${depositState === "success" && "successModal"}`}
      closeModal={closeModal}
      title=""
      showCloseBtn={false}
    >
      <div className="wallet-modal-container">
        <i className="ri ri-close-line close-btn" onClick={closeModal}></i>
        {selectedForm}

        {visibleFooter && moveNextActive && (
          <div className="wallet-modal-footer">
            <button className="btn-secondary" onClick={closeModal}>
              Cancel
            </button>
            <button
              className={`btn-primary ${!withdrawType ? "disabledBtn" : ""}`}
              onClick={setForm}
            >
              next
            </button>
          </div>
        )}
      </div>
    </NewModal>
  );
};
