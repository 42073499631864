import React from "react";
import { imageConfig } from "@utils/imageConfig";

import "./Noasset.scss";

export const Noasset = () => {
  const {svg: {noAssetImage}} = imageConfig;
  
  return (
    <div className="noasset-container">
      <img className="no-data" src={noAssetImage} alt="No Asset" />

      <div className="asset-details">
        <div className="text-url">Invalid asset URL</div>
        <div className="asset-url-invalid">
          The entered URL for the asset is invalid or does not exist. Please
          check the URL or contact our support for assistance.
        </div>
        <div className="contact-email">
          Contact :<span className="email-color">support@liquidity.io</span>
        </div>
      </div>
    </div>
  );
};
