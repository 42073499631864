export interface CountryInfo {
    countryCode: string;
    minLength: number;
    maxLength: number;
  }
  
  export const COUNTRY_PHONE_LENGTHS: Record<string, CountryInfo> = {
    "+93": { countryCode: "+93", minLength: 9, maxLength: 9 },
    "+54": { countryCode: "+54", minLength: 10, maxLength: 10 },
    "+61": { countryCode: "+61", minLength: 9, maxLength: 9 },
    "+43": { countryCode: "+43", minLength: 10, maxLength: 11 },
    "+880": { countryCode: "+880", minLength: 10, maxLength: 10 },
    "+32": { countryCode: "+32", minLength: 9, maxLength: 9 },
    "+55": { countryCode: "+55", minLength: 10, maxLength: 11 },
    "+1": { countryCode: "+1", minLength: 10, maxLength: 11 },
    "+86": { countryCode: "+86", minLength: 11, maxLength: 11 },
    "+45": { countryCode: "+45", minLength: 8, maxLength: 8 },
    "+20": { countryCode: "+20", minLength: 10, maxLength: 10 },
    "+33": { countryCode: "+33", minLength: 9, maxLength: 9 },
    "+49": { countryCode: "+49", minLength: 10, maxLength: 11 },
    "+233": { countryCode: "+233", minLength: 9, maxLength: 9 },
    "+30": { countryCode: "+30", minLength: 10, maxLength: 10 },
    "+91": { countryCode: "+91", minLength: 10, maxLength: 10 },
    "+62": { countryCode: "+62", minLength: 10, maxLength: 11 },
    "+98": { countryCode: "+98", minLength: 10, maxLength: 10 },
    "+39": { countryCode: "+39", minLength: 9, maxLength: 10 },
    "+81": { countryCode: "+81", minLength: 10, maxLength: 10 },
    "+254": { countryCode: "+254", minLength: 9, maxLength: 9 },
    "+60": { countryCode: "+60", minLength: 9, maxLength: 10 },
    "+52": { countryCode: "+52", minLength: 10, maxLength: 10 },
    "+977": { countryCode: "+977", minLength: 10, maxLength: 10 },
    "+31": { countryCode: "+31", minLength: 9, maxLength: 9 },
    "+234": { countryCode: "+234", minLength: 10, maxLength: 10 },
    "+92": { countryCode: "+92", minLength: 10, maxLength: 10 },
    "+63": { countryCode: "+63", minLength: 10, maxLength: 10 },
    "+7": { countryCode: "+7", minLength: 10, maxLength: 10 },
    "+966": { countryCode: "+966", minLength: 9, maxLength: 9 },
    "+27": { countryCode: "+27", minLength: 9, maxLength: 9 },
    "+82": { countryCode: "+82", minLength: 10, maxLength: 10 },
    "+34": { countryCode: "+34", minLength: 9, maxLength: 9 },
    "+90": { countryCode: "+90", minLength: 10, maxLength: 10 },
    "+971": { countryCode: "+971", minLength: 9, maxLength: 9 },
    "+44": { countryCode: "+44", minLength: 10, maxLength: 10 },
    "+84": { countryCode: "+84", minLength: 9, maxLength: 10 },
  };
  