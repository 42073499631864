import React, { useCallback, useEffect, useMemo, useState } from "react";
import UseOrdersApi from "@views/exchange/components/Orders/store/hook";
import { Json } from "../../../types/common";
import { SkeletonWallet } from "@components/skeleton-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { ORDER_STATUS_DISPLAY } from "../constants";
import { MUTUAL_FUND, ORDER_TAGS, ORDER_TYPE, emptyOrder } from "../constants";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import { useCurrency } from "@hooks/currency";
import { useNavigate } from "react-router-dom";
import { convertToDays, getTime } from "@utils/getTime";
import { format, parseISO } from "date-fns";
import classNames from "classnames";
import "./order-list.scss";
import { getCategoryLabel, mergeStringWithSymbol } from "@utils/getString";
import { formatNumberWithCommas } from "@utils/format-number";
import { CountdownTimer } from "@components/counter";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { assestType, ASSETS_CONFIG } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";
interface IOrderCard {
  activeTab: string;
  ordersData:Json;
  setOrdersData:any
}

const OrdersList = ({ activeTab,ordersData,setOrdersData }: IOrderCard) => {
    const darkMode = useDarkMode(false);
    const initialQuery = useMemo(() => {
      return { limit: 20, offset: 0 };
    }, []);
    const navigate = useNavigate();
    const [filters, setFilters] = useState(initialQuery);

    const { fetchOrders } = UseOrdersApi();
    const { formatCurrencyWithBillion } = useCurrency();

    useEffect(() => {
      setFilters(initialQuery);
    }, [initialQuery, activeTab]);

    const ordersForActiveTab = ordersData[activeTab as keyof typeof ordersData];
    const ordersLength = Array.isArray(ordersForActiveTab)
      ? ordersForActiveTab.length
      : 0;

    const handleFetchMore = useCallback(async () => {
      let query: Json = {
        ...filters,
        offset: filters.offset + 1,
        type: activeTab,
      };
      const options = { updateNewData: true };
      setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
      fetchOrders(query, activeTab, options,setOrdersData);
    }, [activeTab, fetchOrders, filters]);

    const handleClickOrder = useCallback(
      (order: Json, index: number) => {
        navigate(`/orders/${order?.id}`, {
          state:
            order?.status === ORDER_TYPE?.EXECUTED
              ? {
                  tradeId: order?.tradeId || null,
                }
              : {},
        });
      },
      [navigate]
    );

    const renderLockInTime = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
      if (completed) {
        // update state after timer is over
      }
      const time = completed ? 'No Lockup' : `${!!days ? days + "D :" : ""} ${
        (hours < 10 ? "0" : "") + hours
      }H : ${(minutes < 10 ? "0" : "") + minutes}M : ${(seconds < 10 ? "0" : "") + seconds}S`;
      return (
        <div className="lockInCount">
          <CircularProgressbarWithChildren
            value={100} maxValue={100} className="countCircle" strokeWidth={6} counterClockwise>
          </CircularProgressbarWithChildren>
          <div>
            <span className="timeColor">{time}</span>
          </div>
        </div>
      );
    }, []);

    const lockInTime = useCallback((lockupPeriod: any, tradeUnlockTime: any) => {
      const durationObj = convertToDays(
        lockupPeriod,
        tradeUnlockTime
      );
      return durationObj?.futureDate;
    }, []);
  
   const renderOrderList = useMemo(() => {
    return (ordersData[activeTab] || [])?.map((order: Json, index: number) => {
      const {issuerDetails: {issuerName}, symbol, name, assetType} = order || {};
      const decimal = ASSETS_CONFIG[assetType as string]?.qtyDecimal
      const issuerNameString = assetType === assestType.crypto ? "" : issuerName;
      const showInitialQuantity =
        activeTab === ORDER_TYPE?.PENDING ? (
          <span className="order-assetQty__qty">
            {formatNumberWithCommas(order?.quantity, decimal)}/{formatNumberWithCommas(order?.initialQuantity, decimal)}
          </span>
        ) : activeTab === ORDER_TYPE?.EXECUTED ? (
          <span className="order-assetQty__qty">{formatNumberWithCommas(order?.quantity, decimal)}</span>
        ) : (
          <span className="order-assetQty__qty">
            {formatNumberWithCommas(order?.quantity, decimal)}
            <span className="order-cancelled">
              <span className="order-list-bullet"></span>
                  <span className="order-cancelled__status">
                    {ORDER_STATUS_DISPLAY[order?.status] ||
                      order?.status?.charAt(0).toUpperCase() + order?.status?.slice(1).toLowerCase()}
                  </span>
            </span>
          </span>
        );
      return (
        <li
          className="order-asset"
          key={order?.id}
          onClick={() => handleClickOrder(order, index)}
        >
          <div className="order-assetDetails-left">
            <div className="order-assetDetails">
              <div className="order-assetDetails__logoContainer">
                <Image
                  url={order?.logo}
                  initials={order?.name}
                  className="order-assetDetails__logoContainer__assetLogo"
                  initialsClassName="order-assetDetails__logoContainer__assetLogo"
                />
              </div>
              <div className="order-assetNameSection">
                <div className="order-assetNameSection__assetInfo">
                  <span className="order-assetNameSection__assetName">
                    {assetType === "music"
                      ? symbol : assetType === "horse_trade" ? symbol 
                      : mergeStringWithSymbol(issuerNameString, symbol?.toUpperCase(), ".")}
                  </span>
                  <span className="order-list-bullet"></span>
                  <span className="order-assetNameSection__issuerName">
                    {assetType === "music"
                      ? name : assetType === "horse_trade" ? name 
                      : mergeStringWithSymbol(issuerNameString, name, " ")}
                  </span>
                </div>
                <div className="order-assetQty">
                  QTY.{" "}
                  <span className="order-assetQty__qty">
                    {showInitialQuantity}
                  </span>
                </div>
              </div>
            </div>
            <div className="order-time">
              {" "}
              <span className="order-time__time">
                <i className="ri-time-line" />
                <span>
                  {order?.orderdate ? getTime(order?.orderdate) : "--"}
                </span>
              </span>
              <span className="order-list-bullet"></span>
              <span className="order-time__date">
                <i className="ri-calendar-line" />
                <span>
                  {order?.orderdate
                    ? format(parseISO(order?.orderdate), "MM/dd/yyyy")
                    : "--"}
                </span>
              </span>
            </div>
            {(order?.assetType === "privates" || order?.assetType === "real_estate" || order?.assetType === "horse_trade") &&
              Number(order?.lockupPeriod?.duration) > 0 &&
              activeTab === ORDER_TYPE.EXECUTED &&
              order?.type === "BUY" &&
              order?.tradeType === "primary" && (
                <div className="order-lockup">
                  <p>Lockup : </p>
                  <CountdownTimer
                    dateTime={lockInTime(order?.lockupPeriod, order?.orderdate)}
                    renderer={renderLockInTime}
                  />
                </div>
              )}
          </div>
          <div className="order-assetDetails-right">
            <div className="order-assetDetails-right__top">
              <div className="order-assetDetails-right__amount">
                {formatCurrencyWithBillion(order?.price, decimal)}
              </div>
              <div className="order-assetDetails-right__total-amount">
                <span>Total Amount</span>{" "}
                {formatCurrencyWithBillion(order?.totalamount, decimal)}
              </div>
            </div>

            <div className="order-assetDetails-right__button-section">
              <span
                className={classNames(
                  "order-assetDetails-right__button-section__assetType",
                  "order-category"
                )}
              >
                {order?.symbol === MUTUAL_FUND.Asset
                  ? MUTUAL_FUND.Text
                  : getCategoryLabel(order?.category)}
              </span>
              {!!order?.sbaType && (
                <span
                  className={classNames(
                    "order-assetDetails-right__button-section__assetType",
                    "order-category"
                  )}
                >
                  {order?.sbaType?.replace(/-/g, " ")}
                </span>
              )}
              <span
                className={classNames(
                  "order-assetDetails-right__button-section__assetType",
                  order?.type === "BUY" ? "buyTrade" : "sellTrade"
                )}
              >
                {order?.status === ORDER_TYPE.EXECUTED
                  ? order?.type === ORDER_TAGS.BUY
                    ? ORDER_TAGS.BOT
                    : ORDER_TAGS.SLD
                  : order?.type}
              </span>
            </div>
          </div>
        </li>
      );
    });
  }, [activeTab, formatCurrencyWithBillion, handleClickOrder, ordersData]);

  const {svg: {no_data_light, no_data_dark}} = imageConfig;
 
  return (
    <ul id="scrollableDiv" className="order-assets-container">
      {!ordersData?.loading && !ordersData?.[activeTab]?.length && (
        <div className="order-empty-container">
          <Image
            className="no-data-image"
            fileName={darkMode.value ? no_data_dark : no_data_light}
          />
          <span className="order-empty-text">
            {activeTab === ORDER_TYPE.PENDING
              ? emptyOrder.NO_OPEN_ORDER
              : activeTab === ORDER_TYPE.EXECUTED
              ? emptyOrder.NO_EXECUTED_ORDER
              : emptyOrder.NO_CANCELLED_ORDER}
          </span>
        </div>
      )}
      <InfiniteScroll
        dataLength={ordersLength || 0}
        next={handleFetchMore}
        hasMore={
          ordersLength === filters.offset * filters.limit + filters.limit
        }
        loader={<SkeletonWallet listsToRender={1} />}
        scrollableTarget="scrollableDiv"
      >
        {renderOrderList}
      </InfiniteScroll>
      {ordersData?.loading && <SkeletonWallet listsToRender={3} />}
    </ul>
  );
};

export default OrdersList;
