import { Button, Loader } from "components"
import cn from "classnames";
import { Image } from "@storybook";
import { useCallback, useState } from "react";
import { UseAuthorisedUser } from "../../store";

import styles from "./removalRequest.module.sass"
import TextArea from "@components/TextArea";
import useDarkMode from "use-dark-mode";
import { useCookie } from "@hooks/cookie";
import { useNotification } from "@hooks/notification";
import { useNavigate } from "react-router-dom";
import { imageConfig } from "@utils/imageConfig";


const RemovalRequest = () => {
    const maxCharacterCount = 120;
    const darkMode = useDarkMode()
    const [currentFormState, setCurrenTformState] = useState("");
    const [counLetter, setCountLetter] = useState(0)

    const { errorNotification } = useNotification();
    const { denyLoading, denyRemovalRequest } = UseAuthorisedUser()
    const { get: getCookieData, } = useCookie();
    const ownerId = getCookieData("ownerId");
    const { id } = ownerId ?? {};
    const navigate = useNavigate();
    
    const {logo: {Liquidity_Blue_Logo}} = imageConfig;
  
    const handleDeny = useCallback((id: string) => {
        if (counLetter === 0) {
            errorNotification("Please state the reason for denying request")
        } else {
            const payload = {
                isAccepted: false,
                denyReason: currentFormState,
            };
            denyRemovalRequest(payload, id)
        }

    }, [counLetter, currentFormState, denyRemovalRequest, errorNotification])

    const onChangeHandler = useCallback((e: any) => {
        const inputValue = e.target.value;
        if (inputValue.length <= maxCharacterCount) {
            setCurrenTformState(inputValue);
            setCountLetter(inputValue.length);
        }
    }, []);
    const handleLogo = useCallback(()=>{
        navigate("/exchange")
    },[navigate])

    return (
        <>
            <div className={styles.removalRequestContainer}>
                <div className={styles.removalRequestSection}>
                    <div >
                        <Image
                            className={styles.logoContainer}
                            fileName={Liquidity_Blue_Logo}
                            props={{onClick:handleLogo}}
                        />
                    </div>
                    <div className={styles.removalRequestSection__main}>

                        <div className={styles.removalRequestSection__main__details}>
                            <div className={styles.removalRequestSection__main__title}>Co-owner removal request</div>
                            <p>Removal request process denied. Please state the reason for denying request.</p>
                            <div className={styles.removal_textArea}>
                                <TextArea
                                    className={styles.removalTextArea}
                                    inputType="text"
                                    placeholder="Write reason here"
                                    label=""
                                    value={currentFormState}
                                    onChange={onChangeHandler}
                                    name="description"
                                />
                                <div className={styles.wordCount}>{`${counLetter}/${maxCharacterCount}`}</div>

                            </div >
                            <div className={styles.buttonSection} >
                                <Button
                                    label={"Back"}
                                    handleClick={() => { window.history.back() }}
                                    type={cn(styles.deny)}
                                />
                                {
                                    <Button
                                        label={denyLoading ? <Loader dimension={20} className='loader-white' /> : "Deny request "}
                                        handleClick={() => handleDeny(id)}
                                        type={cn("button__filled button__filled--primary", styles.DenyRequest)}

                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.removal_invitationFooter}>
                        <div className={styles.invitaionSocial}>
                            <span className={styles.invitaionSocial__twitterLogo}>
                                <a href="https://www.linkedin.com/company/liquidity-io/" > <img src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/linkedIn.svg" alt="LinkedIn" /></a>
                            </span>
                            <span className={styles.invitaionSocial__twitterLogo}>
                                <a href="https://twitter.com/LiquidityIO" > <img className={styles.invitaionSocial__twitterImg} src="https://storage.googleapis.com/satschel-assets-public/assets/illustrations/twitter-logo.png" alt="Twiiter" /></a>
                            </span>
                        </div>
                        <p className={styles.footerDisclaimer}>© 2024 Satschel Inc. All rights reserved.</p>
                    </div>

                </div >
            </div >
        </>
    )
}
export default RemovalRequest