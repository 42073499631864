import useDarkMode from "use-dark-mode";
import { FC, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { ReportChartsState } from "states";
import { useCurrency } from "hooks";
import { Skeleton } from "components";

import styles from "./chart-details.module.sass";
import { BarChart } from "components/Charts";
import "./chart-details.scss";

interface IPortfolio {
  loader: boolean;
}

export const Portfolio: FC<IPortfolio> = ({ loader }) => {
  const { formatCurrencyWithBillion } = useCurrency();

  const reportCharts = useRecoilValue(ReportChartsState);

  const port_folio = useMemo(() => {
    const { summary, chartData } = reportCharts[0] ?? {};
    const { totalInvestment = "0.00", currentValue = "0.00" } = summary ?? {};
    const { portfolio = [], XAxis = [] } = chartData ?? {};
    return { totalInvestment, currentValue, portfolio, XAxis };
  }, [reportCharts]);

  const defaultDataValue = useMemo(() => {
    const checkDataValue = port_folio.portfolio
      .map((item: any) => item.data.reduce((a: number, b: number) => a + b, 0))
      .reduce((a: number, b: number) => a + b, 0);
    let yMaxValue;
    if (checkDataValue === 0) {
      yMaxValue = 100;
    } else {
      yMaxValue = null;
    }
    return yMaxValue;
  }, [port_folio.portfolio]);

  const portfolioDetail = useMemo(
    () => (
      <div className={styles.portfolio}>
        <div className={styles.portfolio__text}>Portfolio</div>
        <div className={styles.portfolio__valueData}>
          <div className={styles.portfolio__sub_text}>
            Invested :{" "}
            <span className={styles.portfolio__sub_text_value}>
              {loader ? (
                <Skeleton />
              ) : (
                formatCurrencyWithBillion(port_folio.totalInvestment, 2)
              )}
            </span>
          </div>
          <div className={styles.portfolio__sub_text}>
            Current :{" "}
            <span className={styles.portfolio__sub_text_value}>
              {loader ? (
                <Skeleton />
              ) : (
                formatCurrencyWithBillion(port_folio.currentValue, 2)
              )}
            </span>
          </div>
        </div>
        <BarChart
          isLoading={loader}
          columnColor={["#458BF5", "#E3E7FC"]}
          data={port_folio.portfolio}
          chartName={"portfolio"}
          xAxis={port_folio.XAxis}
          yMax={defaultDataValue}
        />
      </div>
    ),
    [
      defaultDataValue,
      formatCurrencyWithBillion,
      loader,
      port_folio.XAxis,
      port_folio.currentValue,
      port_folio.portfolio,
      port_folio.totalInvestment,
    ]
  );
  return <>{portfolioDetail}</>;
};
