import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNotification } from "@hooks/notification";
import { Button } from "@components/button";
import "./user-account-modal.scss";
import { Image } from "../../../../@storybook";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  LoginUserState,
  TriggerUserApi,
  userPersonalDetails,
  userProfileState,
} from "@states/user";
import cn from "classnames";
import { SkeletonLogo, SkeletonWallet } from "@components/skeleton-loader";
// @ts-ignore
import { Json } from "@types/common";
import { APIS, VALID_IMAGE_FILE_FORMAT } from "../../../../constant";
import { useNetwork } from "@hooks/network";
import { Loader } from "@components/Loader2";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useCookie } from "@hooks/cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { REACT_APP_API_HOST as API_HOST } from "../../../../envs";
import { signInWithTokenAndSetPersistence } from "../../../../libs";
import { convertFileToBase64 } from "@utils/base64";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

type IUserAccountModal = {
  open: boolean;
  onClose: () => void;
  onLogout?: () => void;
  onClickCreateAccount?: () => void;
  setShowKYCReview?: any;
};
const UserAccountModal = ({
  open,
  onClose,
  onLogout,
  onClickCreateAccount,
  setShowKYCReview
}: IUserAccountModal) => {
  const { errorNotification, successNotification } = useNotification();
  const userDetails = useRecoilValue(userPersonalDetails);
  const { get: fetchAccounts, loading: accountsLoading } = useNetwork();
  const { post: switchAccountsApi, loading: switchLoading } = useNetwork();
  const { set: setCookieToken, get: getCookieData } = useCookie();
  const darkMode = useDarkMode();
  const navigate = useNavigate();
  const setLoginUser = useSetRecoilState(LoginUserState);
  const [profilePic, setProfilePic] = useRecoilState(userProfileState);
  const setTriggerUserApi = useSetRecoilState(TriggerUserApi);
  const [accountList, setAccountList] = useState([]);
  const switchUserRef = useRef<Json>({});
  const uploadRef = useRef<any>(null);
  const { post: changeSettings, loading: picUploading } = useNetwork();

  const {svg: {avatar_dark, avatar_light}} = imageConfig;

  useEffect(() => {
    handelFetchAccount();
  }, []);

  const handelFetchAccount = useCallback(
    async (query: Json = {}): Promise<void> => {
      try {
        const res = await fetchAccounts(APIS.WORK_SPACE);
        if (res?.message === "ok") {
          const data = res?.data?.filter(
            (user: Json) => user?.customerId !== userDetails?.customerId
          );
          setAccountList(data || []);
        } else {
          errorNotification(res?.message || "Something went wrong!");
        }
      } catch (e: any) {
        errorNotification(e?.message || "Something went wrong!");
      }
    },
    [userDetails?.customerId]
  );

  async function fetchDataWithToken(token: string) {
    try {
      const response = await axios.get(API_HOST + APIS.FIREBASE, {
        headers: {
          Authorization: `Bearer ${token}`, // Set Bearer token in Authorization header
        },
      });

      // Log or handle the response data
      if (response.data) {
        signInWithTokenAndSetPersistence(response.data.data.token);
      }
      return response.data;
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  const handelSwitchWorkSpace = useCallback(
    async (user: Json = {}): Promise<void> => {
      if (switchLoading) return;
      switchUserRef.current = user;

      try {
        const res = await switchAccountsApi(
          APIS.WORK_SPACE_SWITCH,
          { customerId: user?.customerId },
          { apiResponse: true }
        );
        if (res?.message === "ok") {
          const userCookie = getCookieData("userDetails");
          const loginDetails = {
            ...userCookie,
            token: res?.data?.data?.token,
          };
          setCookieToken("userDetails", loginDetails);
          fetchDataWithToken(res?.data?.data?.token);
          setLoginUser((prevState) => ({
            ...prevState,
            token: res?.data?.data?.token,
          }));
          window.location.reload();
        } else {
          errorNotification(res?.message || "Something went wrong!");
        }
      } catch (e: any) {
        errorNotification(e?.message || "Something went wrong!");
      }
    },
    [switchLoading]
  );

  const handleProfileChange = useCallback(
    async (e: any) => {
      const file = e.target.files?.[0];
      if (file?.type && !VALID_IMAGE_FILE_FORMAT[file.type])
        return errorNotification(
          "Invalid Image. Please upload png, jpg, jpeg file only"
        );

      if (file) {
        if (file.size <= 4 * 1024 * 1024) {
          const fileName: any = await convertFileToBase64(file);
          const logo = fileName.toString();

          const payload = {
            type: file.type,
            image: logo,
          };
          changeSettings(`${APIS.UserProfile}`, payload, { apiResponse: true }).then((res) => {
            if (res?.message === "ok") {
              setProfilePic(res?.data);
              successNotification("Image Uploaded");
              setTriggerUserApi((prev) => !prev);
            } else {
              errorNotification(res?.message);
            }
          }).catch((error) => {
            errorNotification("Something went wrong");
          });
        } else {
          errorNotification("Image size exceeds 4MB limit");
        }
      }
    },
    [
      changeSettings,
      errorNotification,
      setProfilePic,
      setTriggerUserApi,
      successNotification,
    ]
  );

  const handleClickNavigate = useCallback((action: string) => {
    if (action === "profile") navigate("/profile");
    if (action === "admin") {
      if (!userDetails?.onboardingData?.isOnboardingComplete) {
        setShowKYCReview(true)
      } else {
        navigate("/admin")
      }
    }
    onClose();
  }, [userDetails?.onboardingData?.isOnboardingComplete]);

  const renderProfileName = useCallback((account: Json) => {
    return account?.accountName
      ? account?.accountName
      : account?.email
        ? account?.email
        : account?.customerId;
  }, []);

  return (
    <div className="switch-account-popup">
      <i className="ri-close-line" onClick={onClose} />
      <div className="user-details-container user-section">
        <div className="user-img">
          {picUploading ? (
              <SkeletonLogo />
            ) :
            <Image className="user-img" fileName={darkMode.value ? avatar_dark : avatar_light}
                   useFileNameOnFail {...!!profilePic && { url: profilePic }} />
          }
          <i className="ri-pencil-fill" onClick={() => uploadRef.current.click()} />
          <input
            type="file"
            id="upload-img"
            accept="image/png, image/jpeg , image/jpg"
            onChange={handleProfileChange}
            ref={uploadRef}
            hidden
          />
        </div>

        <div className="user-details">
          <div className="user-details-name">
            {!!userDetails?.firstName
              ? userDetails?.firstName + " " + userDetails?.lastName
              : "LIQUIDITYUSER"}
          </div>
          <div>
            <div
              className="user-details-account-email"
              data-tooltip-id="emailTooltip-user-account" // Unique ID for the tooltip
              data-tooltip-content={userDetails?.email || "No email available"} // Tooltip content
            >
              {userDetails?.email || "--"}
            </div>
            <ReactTooltip
              id="emailTooltip-user-account"
              place="bottom"
              className="theme-tooltip custom-tooltip"
            />
          </div>
          <div className="user-details-account-email">
            Account ID: {userDetails?.customerId}
          </div>
        </div>
      </div>

      <div className="account-btn-group">
        <Button
          label={
            <>
              <i className="ri-user-settings-line" /> Account Settings
            </>
          }
          handleClick={() => handleClickNavigate("profile")}
          type="btn-light-blue btn-h-auto"
        />
        <span className="btn-divider" />
        <Button
          handleClick={onLogout}
          label={
            <>
              <i className="ri-logout-box-r-line" /> Logout
            </>
          }
          type={cn("button__filled gray-btn")}
        />
      </div>

      {userDetails?.isAdmin && (
        <div
          className="user-details-container add-user-account admin"
          onClick={() => handleClickNavigate("admin")}
        >
          <i className="ri-admin-line icon" />
          <div className="user-details">
            <div className="user-details-name">Admin</div>
            <div className="user-details-acc-id create-btn">
              Mint, list, and manage assets.
            </div>
          </div>
        </div>
      )}

      <div className="switch-account-list-container">
        <div className="switch-account-heading">
          Switch Account
          {!!accountList?.length && (
            <Button
              label={
                <>
                  <i className="ri-add-line" />
                  Create account
                </>
              }
              handleClick={onClickCreateAccount}
              type={cn("button__filled button__filled--primary")}
            />
          )}
        </div>

        {!accountsLoading && !accountList?.length && (
          <div className="switch-account-list">
            <div
              className="user-details-container add-user-account create-new"
              onClick={onClickCreateAccount}
            >
              <Image className="user-img" fileName="icon/blue-add.svg" />
              <div className="user-details">
                <div className="user-details-name">Create new account</div>
                <div className="user-details-acc-id create-btn">
                  Create a new account to switch between <br /> accounts
                </div>
              </div>
            </div>
          </div>
        )}

        {accountsLoading && (
          <div className="switch-account-list">
            <SkeletonWallet listsToRender={2} />
          </div>
        )}
        {!accountsLoading && !!accountList?.length && (
          <div className="switch-account-list">
            {accountList?.map((account: Json) => (
              <div className="user-details-container add-user-account" key={account?.customerId}
                   onClick={() => handelSwitchWorkSpace(account)}>
                <Image className="user-img"
                       fileName={darkMode.value ? avatar_dark : avatar_light}
                       useFileNameOnFail {...!!account?.profileUrl && { url: account?.profileUrl }} />
                <div className="user-details switch-tag-wrapper">
                  <div
                    className="user-details-name"
                    data-tooltip-id={account?.customerId + "name"}
                  >
                    {renderProfileName(account)}
                  </div>
                  {renderProfileName(account)?.length >= 20 && (
                    <ReactTooltip
                      className="theme-tooltip custom-tooltip"
                      id={account?.customerId + "name"}
                      place="top"
                      content={renderProfileName(account)}
                    />
                  )}

                  <div
                    className="user-details-acc-id"
                    data-tooltip-id={account?.customerId + "mail"}
                  >
                    {account?.email}
                  </div>
                  {account?.email?.length >= 25 && (
                    <ReactTooltip
                      className="theme-tooltip custom-tooltip"
                      id={account?.customerId + "mail"}
                      place="top"
                      content={account?.email}
                    />
                  )}
                  <div className="user-details-acc-id">
                    Account ID: {account?.customerId}
                  </div>
                </div>
                {switchUserRef?.current?.customerId === account?.customerId &&
                switchLoading ? (
                  <span className="switch-tag">
                    <Loader dimension={20} />
                  </span>
                ) : (
                  <span className="switch-tag">
                    Switch <i className="ri-arrow-right-s-line" />
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccountModal;
