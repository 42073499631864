import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { DatePicker,Loader } from "components";
import { useNetwork, useNotification } from "hooks";
import { getFormattedDate, getJsonToParams } from "utils";
import {
  ReportChartsState,
  SelectedDateRangeState,
  transactionDataState,
} from "states";
import { APIS } from "constant";
import { Portfolio, ProfitLoss, ReportTable } from "./components";


import styles from "./report.module.sass";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { Json } from "../../types";

export const Reports = () => {
  const initialQuery={ limit: 20, offset: 0 }
  const [filters, setFilters] = useState(initialQuery);
  
  const setTransactionData = useSetRecoilState(transactionDataState);
  const resetTransaction = useResetRecoilState(transactionDataState);
  const setReportCharts = useSetRecoilState(ReportChartsState);
  const selectedDateRange = useRecoilValue(SelectedDateRangeState);
  const { errorNotification } = useNotification();
  const transactionRef = useRef<any>();
  const [reportData, setReportData] = useState<any>([]); //Todo
  const { endDate, startDate } = selectedDateRange[0];

  const { get: formDownload, loading: loading_8949 } = useNetwork();
  const { post: updateReports, loading } = useNetwork();
  const { trackPageEvent } = useFullStoryTrackEvent();

  const {
    get: getReportCharts,
    data: reportCharts,
    loading: chartLoader,
  } = useNetwork();

  const {
    get: getTransactionReports,
    data: getTransactionData,
    loading: reportDataLoading,
  } = useNetwork();

  useEffect(() => {
    trackPageEvent({
      pageName: 'Reports',
    });
  }, []);

  useEffect(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);
    
    getReportCharts(`${APIS.REPORTS_CHARTS}?to=${endDates}&from=${fromDate} `);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    // reset the state
    resetTransaction();
    setReportData([])
    setFilters(initialQuery)
    if (transactionRef.current) {
      transactionRef.current.scrollTo({
        top: 0,
      });
    }
  }, [selectedDateRange])


  useEffect(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);
    const query = {
      to: endDates,
      from: fromDate,
      ...filters
    }
    getTransactionReports(
      `${APIS.Transaction_exchange}` + getJsonToParams(query)
    );

  }, [filters]);

  useEffect(() => {
    if (getTransactionData?.data) {
      setTransactionData((prev: Json[]) => [...prev, ...getTransactionData?.data]);
      setReportData((prev: Json[]) => [...prev, ...getTransactionData?.data]);
    }
  }, [getTransactionData?.data, setTransactionData]);

  useEffect(() => {
    if (reportCharts?.data) {
      setReportCharts(reportCharts.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportCharts?.data]);

  const handleFetchMore = useCallback(() => {
    setFilters((prev) => ({ ...prev, offset: prev.offset + 1 }));
  }, []);

  const formatDate = useCallback((date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }, []);

  const handleOnClickDateFilter = useMemo(() => {
    const fromDates = new Date(formatDate(startDate)).getTime();
    const endDates = new Date(formatDate(endDate)).getTime();
    const filteredArr = [...reportData]?.filter((item) => {
      let date = new Date(formatDate(item.dateOfBuy)).getTime();
      if (date >= fromDates && date <= endDates) {
        return item;
      }
      return null;
    });
    // setTransactionData(filteredArr);
  }, [endDate, formatDate, reportData, startDate]);

  const handleStatement = useCallback(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);

    updateReports(
      `${APIS.MONTHLYSTATEMENT}
  `,
      { to: endDates, from: fromDate }
    ).then((res) => {
      if (res.signedUrl) {
        window.open(res.signedUrl, "_blank");
      } else {
        errorNotification("Not able to download please try after sometime");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const handleDownload_8949 = useCallback(() => {
    const { endDate, startDate }: any = selectedDateRange[0];
    const fromDate = getFormattedDate(startDate);
    const endDates = getFormattedDate(endDate);
    formDownload(`${APIS.FormDownload}?to=${endDates}&from=${fromDate}`).then(
      (res) => {
        window.open(res.data?.[0]?.formSignedUrl, "_blank");
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const report_Charts = useMemo(() => {
    return (
      <div className={styles.charts}>
        <ProfitLoss loader={chartLoader} />
        <Portfolio loader={chartLoader} />
      </div>
    );
  }, [chartLoader]);

  return (
    <div className={styles.reports_container}>
      <div className={styles.container}>
        <DatePicker onSubmit={() => handleOnClickDateFilter} />
        <div className={styles.report_wrapper_button}>
          <div
            className={`${styles.container_button} ${styles.container_secondaryButton}`}
            onClick={handleStatement}
          >
            {loading ? (
              <Loader className="loader-white" dimension={18} />
            ) : (
              <>
                <i className="ri-download-fill" />
                Monthly Statement
              </>
            )}
          </div>
          <div
            className={styles.container_button}
            onClick={handleDownload_8949}
          >
            {loading_8949 ? (
              <Loader className="loader-white" dimension={18} />
            ) : (
              <>
                <i className="ri-download-fill" /> Download (8949)
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.chart_table_container}>
        {report_Charts}

        <div className={styles.table_report}>
            <ReportTable
              refToTable={transactionRef}
              loading={reportDataLoading}
              filters={filters}
              handleFetchMore={handleFetchMore}
            />
        </div>
      </div>
    </div>
  );
};
export default Reports
