import React, { createContext, useContext, useEffect, useState } from "react";
import { AppConfig } from "./types";
import { useNetwork } from "@hooks/network";
import { mapApiResponseToAppConfig } from "./configAdapter";
import { Json } from "../../types";
import { APIS, defaultAccessHost } from "constant";
import { defaultPermissionCnnfig } from "./constant";

type PermissionContextType = {
  config: AppConfig | null;
  loading: boolean;
  SubRouteEnabled: any;
  hasRouteAccess: any;
  tabPermissions: any;
};

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

type PermissionProviderProps = {
  children: React.ReactNode;
};

export const PermissionProvider: React.FC<PermissionProviderProps> = ({
  children,
}) => {
  const [config, setConfig] = useState<AppConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [isLoaded, setIsloaded] = useState(false);
  
  const { get: getTenantConfig } = useNetwork();

  useEffect(() => {
    const fetchConfig = async () => {
      setLoading(true);
      getTenantConfig(APIS.tenants)
        .then((response: any) => {
          if (response.message === "ok") {
            const mappedConfig = mapApiResponseToAppConfig(response?.data);
            setConfig(mappedConfig);
          }
          else{
            if(defaultAccessHost.includes(window.location.hostname)) {
              const mappedConfig = mapApiResponseToAppConfig(defaultPermissionCnnfig?.data);
              setConfig(mappedConfig);
            }
          }
        })
        .catch((err: any) => {
          console.error("Failed to fetch configuration:", err);
        })
        .finally(() => {
          setLoading(false);
          setIsloaded(true);
        });
    };

    fetchConfig();
  }, []);

  const tabPermissions = (navigation: Json[]) => {
    if ((!config || !config.tabs) && isLoaded ) return navigation;

    return navigation.filter((navItem) => {
      // Find the corresponding tab in config.tabs
      const tab: any = config?.tabs.find(
        (tab: any) => tab?.name.toLowerCase() === navItem.title.toLowerCase()
      );

      // Check if the tab exists and if the 'view' permission is true
      return tab && tab?.permission?.view === true;
    });
  };

  const SubRouteEnabled = (tabName: string): string[] => {
    // Check if config is available and tabs exist
    if ((!config || !config.tabs) && isLoaded ) return [];

    // Find the tab based on tabName
    const tab = config?.tabs?.find(
      (tab) => tab.name.toLowerCase() === tabName.toLowerCase()
    );

    if (!tab || !tab.subFeatures) return [];

    // Filter subfeatures where 'view' permission is true
    const enabledSubFeatures = tab.subFeatures
      .filter((subFeature) => subFeature.permissions.view === true)
      .map((subFeature) => subFeature.name);

    return enabledSubFeatures;
  };

  // Helper to check if a route is accessible
  const hasRouteAccess = (tabName: string): boolean => {
    if ((!config || !config.tabs) && isLoaded ) return true;

    const tab: any = config?.tabs?.find(
      (tab) => tab?.name.toLowerCase() === tabName?.toLowerCase()
    );

    return tab?.permission?.view ?? false;
  };

  return (
    <PermissionContext.Provider
      value={{
        config,
        loading,
        SubRouteEnabled,
        hasRouteAccess,
        tabPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error(
      "usePermissionContext must be used within PermissionProvider"
    );
  }
  return context;
};
